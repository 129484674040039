import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ItermImg from '../images/iterm.png';
import PhpStormImg from '../images/phpstorm.png';
import VsCodeImg from '../images/vscode.png';

const classes = {
  wrapper: 'mt-16 blog-content',
  title: 'mt-16 text-4xl text-gray-900 font-bold',
};

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO title="Uses" />
      <Header metadata={data.site.siteMetadata} />
      <div className={classes.wrapper}>
        <p>
          I always loved to check other people's "uses" page and spent countless
          hours on{' '}
          <a href="uses.tech" target="_blank" rel="noreferrer">
            uses.tech
          </a>{' '}
          until I decided to put together my own "uses" page that covers the
          tools I use on a daily basis. I have a tendency of changing things up
          quite often, so I'll do my best to keep this page up to date. If there
          is something missing, shoot me a tweet at{' '}
          <a href="https://twitter.com/mvpopuk">@mvpopuk</a>
        </p>

        <h4 className="uppercase">Workstation</h4>
        <ul>
          <li>
            <a
              href="https://www.apple.com/shop/buy-mac/mac-mini/apple-m1-chip-with-8-core-cpu-and-8-core-gpu-256gb"
              target="_blank"
              rel="noreferrer"
            >
              Mac Mini 2020 - M1 chip, 8GB unified memory, 256GB SSD storage
            </a>
            <p>
              I recently bought this machine to bust the M1 chip myth but, found
              out it's not a myth and I kind of fell in love with it so it
              became my main working station.
            </p>
          </li>
          <li>
            <a
              href="https://support.apple.com/kb/SP691?locale=ro_RO&viewlocale=en_US"
              target="_blank"
              rel="noreferrer"
            >
              MacBook PRO 2013 - Retina 15 inch, Quad-Core 2.3Ghz I7, 16GB RAM,
              512GB SSD storage
            </a>
            <p>
              As much as I like my M1 powered Mac Mini, I still love working on
              my old, battle-tested macbook.
            </p>
          </li>
        </ul>
        <h4 className="uppercase">Development</h4>
        <ul>
          <li>
            <a
              href="https://www.jetbrains.com/phpstorm/"
              target="_blank"
              rel="noreferrer"
            >
              <b>PhpStorm</b>
            </a>{' '}
            <p>
              I use it daily for my full-time job since we develop and maintain
              a Laravel + Vue app where I'm mostly doing PHP. I use a slightly
              modified version of the{' '}
              <a
                href="https://plugins.jetbrains.com/plugin/10936-night-owl-theme"
                target="_blank"
                rel="noreferrer"
              >
                Night Owl
              </a>{' '}
              theme paired with the{' '}
              <a
                href="https://www.jetbrains.com/lp/mono/"
                target="_blank"
                rel="noreferrer"
              >
                JetBrains Mono
              </a>{' '}
              font. I also bought{' '}
              <a
                href="https://www.material-theme.com/"
                target="_blank"
                rel="noreferrer"
              >
                Material UI
              </a>{' '}
              because it lets me modify some parts of the IDE that I would
              normaly not be able to.
              <a href={PhpStormImg}>
                <img src={PhpStormImg} alt=""></img>
              </a>
            </p>
          </li>
          <li>
            <a
              href="https://code.visualstudio.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Visual Studio Code</b>
            </a>{' '}
            <p>
              Since almost all of my personal / side projects are being built in
              React or Vue, I use{' '}
              <a
                href="https://twitter.com/code"
                target="_blank"
                rel="noreferrer"
              >
                @code
              </a>{' '}
              as my primarily code editor when it comes to Javascript. I love
              and use the default{' '}
              <a href="" target="_blank" rel="noreferrer">
                Dark+
              </a>{' '}
              theme with{' '}
              <a
                href="https://www.jetbrains.com/lp/mono/"
                target="_blank"
                rel="noreferrer"
              >
                JetBrains Mono
              </a>{' '}
              and, at least for Javascript, I think it's the best theme
              available. I also use{' '}
              <a
                href="https://marketplace.visualstudio.com/items?itemName=vscodevim.vim"
                target="_blank"
                rel="noreferrer"
              >
                VIM key bindings
              </a>
              . You can see my plugins and settings{' '}
              <a
                href="https://gist.github.com/mvpopuk/b64e172b93feea0f8859a3b63c406f83"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
              <a href={VsCodeImg}>
                <img src={VsCodeImg}></img>
              </a>
            </p>
          </li>
          <li>
            <a href="https://iterm2.com/" target="_blank" rel="noreferrer">
              <b>ITerm2</b>
            </a>{' '}
            <p>
              I use Iterm2 with{' '}
              <a
                href="https://github.com/tallpants/vscode-theme-iterm2"
                target="_blank"
                rel="noreferrer"
              >
                vscode theme
              </a>{' '}
              and{' '}
              <a
                href="https://www.jetbrains.com/lp/mono/"
                target="_blank"
                rel="noreferrer"
              >
                JetBrains Mono
              </a>
              , paired with{' '}
              <a href="https://ohmyz.sh/" target="_blank" rel="noreferrer">
                Oh My Zsh
              </a>
              ,{' '}
              <a
                href="https://github.com/spaceship-prompt/spaceship-prompt"
                target="_blank"
                rel="noreferrer"
              >
                Spaceship ZSH{' '}
              </a>{' '}
              and{' '}
              <a
                href="https://github.com/zsh-users/zsh-autosuggestions"
                target="_blank"
                rel="noreferrer"
              >
                ZSH Autosuggestions
              </a>
              .
              <a href={ItermImg}>
                <img src={ItermImg} alt=""></img>
              </a>
            </p>
          </li>
          <li>
            <a href="https://tableplus.com/" target="_blank" rel="noreferrer">
              <b>Table Plus</b>
            </a>
            <p>
              I use Table Plus whenever I need to interact with a database
              through a graphical interface. I use it with the dark mode theme.
            </p>
          </li>
        </ul>
        <h4 className="uppercase">Design</h4>
        <ul>
          <li>
            <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
              <b>Figma</b>
            </a>{' '}
            <p>
              When working on my own side projects and I need to have a clear
              idea in mind of what I need to code, I always use Figma to create
              wireframes.
            </p>
          </li>
        </ul>
        <h4 className="uppercase">Productivity</h4>
        <ul>
          <li>
            <a href="https://www.notion.so/" target="_blank" rel="noreferrer">
              <b>Notion</b>
            </a>{' '}
            <p>
              I use Notion mostly as my project manager for my side projects and
              whatnot and for when I need to write something and share that note
              with someone. I think it's a great app and I also have plan to
              play around with their newly released public API.
            </p>
          </li>
          <li>
            <a href="https://bear.app/" target="_blank" rel="noreferrer">
              <b>Bear</b>
            </a>{' '}
            <p>
              Great app, I use it whenever I feel the need of taking notes
              regardless of the topic.
            </p>
          </li>
        </ul>
        <h4 className="uppercase">Hosting</h4>
        <ul>
          <li>
            <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
              <b>Netlify</b>
            </a>{' '}
            <p>For all of my Javascript powered static websites.</p>
          </li>
          <li>
            <a
              href="https://www.digitalocean.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Digital Ocean + Forge</b>
            </a>{' '}
            <p>For all of my back-end's and anything that involves PHP.</p>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
